import React from "react"
import Layout from "../components/Layout"

export default function Cennik() {
  return (
    <Layout>
      <div>
        <h1></h1>
      </div>
    </Layout>
  )
}
